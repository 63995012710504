@use "~inter-ui/default" with ($inter-font-display: swap,
  $inter-font-path: '~inter-ui/Inter (web)'
);
@use "~inter-ui/variable" with ($inter-font-display: swap,
  $inter-font-path: '~inter-ui/Inter (web)'
);
@include default.all;
@include variable.all;

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", "system-ui" !important;
  }
}

/**
PALETTE
 */
$font-color: #003862;
$icon-color: #53799e;
$primary-color: #0079b9;
$tooltip-color: #16416f;
$error-color: #fe2624;


$grey-1: #24395e; //font color
$grey-2: #f4f6fa; //top-right section bg
$grey-3: #f2f2f2; //top-bottom section bg
$grey-4: #e1e7ed; //borders //c6d2e0
$grey-5: #9cacc0; //card icons
$grey-6: #a6b4c7; //card icons border btns
$grey-7: rgba(229, 229, 229); //card casetype color
$grey-8: #505e8b; //card description
$grey-9: #828282; //george icon in yellow field
$grey-10: #cdd5df; //george icon in yellow field
$grey-11: #f2f4f9; //selected client/product area bg
$grey-12: #a3b5c9; //remove text from filter color

$green-1: #2fa72d;
$green-2: #278725;

$blue-1: #0063ae;
$blue-2: #4487b2;
$blue-3: #6ba4c3;
$blue-4: #afd0e2;
$blue-5: #3567d2; //top navbar color
$blue-6: #e9f0fd;
$blue-7: #e9f3fa;
$blue-8: #3667e4;

$red-1: #f15d5d;
$red-2: #7a2f2f;
$yellow-1: #f4d36d;

$label-color: #0f2740;
$label-icon-color: #cbd6e2;

$shadow: #507abd;
$bgColor: #5d84c3;
$ribbonColor: #fff;

//section related palette
$input-border-color: #b5c2d2;
$input-hint-background-color: #f6f8fa;
$input-hint-icon-color: #b1c1d1;
$input-hashtag-background-color: #0066DA;
$input-hashtag-color: #aaa;
$cards-background-color: #e3eaf1;

$card-icon-color: #55799C;
$card-color: #55799C;

//basic bootstrap colors override
$danger-bg: #FFE8E7;
$danger-border: #EA4035;
$danger-text: #A7100E;

$warning-bg: #FFF0E2;
$warning-border: #E16B02;
$warning-text: #67240D;

$success-bg: #DEFFEC;
$success-border: #19AB52;
$success-text: #1B5D08;

$info-bg: #e1ffff;
$info-border: #48eae4;
$info-text: #1fa0a7;

$secondary-bg: #F5F8FA;
$secondary-border: #A6B4C7;
$secondary-text: #16416F;

$primary-bg: #5B83AE;
$primary-text: #21416C;
$primary-border: #A6BACE;


//z-index
$z-index-zero: 0;
$z-index-lowest: 1;
$z-index-low: 10;
$z-index-middle: 20;
$z-index-high: 30;
$z-index-highest: 40;

/**
VISUAL - radius, widths, heights
 */
$radius: 0.25rem;
$small-radius: 0.3rem;
$border-strength: 0.1rem;
$border-entity-strength: 0.24rem;
$input-border-radius: 0.25rem;

/**
ANIMATIONS
 */
$card-transition-duration: 0.5s;

.active-side-card {
  color: $secondary-text !important;
  fill: $secondary-text !important;
  border: 1px solid $blue-8 !important;
  background: $blue-7 !important;
  transition: all 0.3s;
}

html {
  font-family: "Inter", "system-ui" !important;
}

body {
  font-family: "Inter", "system-ui" !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $font-color !important;
  overflow: hidden;
}

* {
  font-size: 0.68vw;
  line-height: 1.2;
}

*:focus,
input:focus,
input:active {
  outline: none !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.filter-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px $grey-4 solid;
}

;

/**
Top navbar
 */
.navbar-starter {
  background: $blue-5;

  .navbar-text,
  .nav-link {
    color: white !important;
  }
}

.casetypes {
  background: $cards-background-color;
}

/**
Scrollbar
*/
.row::-webkit-scrollbar {
  width: 0.8em;
}

.row::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: #E5E5E5;
}

.row::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: #999999;
}

.search {
  background: white;
}

.product {
  .active {
    color: $green-1
  }

  .inactive {
    color: $red-1
  }

  .name {
    font-size: 1.25rem;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  span {
    font-size: 1.15rem;
  }
}

.client {
  .name {
    font-size: 1.25rem;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  span {
    font-size: 1.15rem;
  }

  .selected {
    &:before {
      border-top-right-radius: $radius;
      content: '';
      position: absolute;
      top: -1px;
      right: -2px;
      border-top: 4rem solid $blue-1;
      border-left: 4rem solid white;
      width: 0;
    }

    .selected-icon {
      z-index: 20;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.1rem;
      fill: white;
    }
  }
}

/**
forms
 */
.input-clear-button {
  position: absolute;
  fill: $grey-12;
  z-index: $z-index-middle;
}

.overlay {
  z-index: $z-index-low;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #aaa;
  opacity: 0.5;
}

.searchinput {

  .searchinput-input-wrapper {
    -webkit-box-shadow: inset 0 0 0 $border-strength $input-border-color;
    -moz-box-shadow: inset 0 0 0 $border-strength $input-border-color;
    box-shadow: inset 0 0 0 $border-strength $input-border-color;
    border-radius: $input-border-radius;
    background: #fff;
    z-index: $z-index-middle !important;
  }

  .input-group {
    z-index: $z-index-middle !important;
    flex: 1 1;

    input {
      z-index: $z-index-middle !important;
      outline: none !important;
      border: 0;
      flex: 1 1;
      box-shadow: none !important;
      background: #fff0 !important;
    }
  }

  .searchinput-suggestions {
    position: absolute;
    z-index: $z-index-middle;
    border-radius: 0 0 $input-border-radius $input-border-radius;
    background-color: white;
    border: $border-strength solid $grey-4 !important;

    .toggle {
      fill: $grey-12;
    }
  }

}

.font-george-icon {
  fill: $font-color;
}

.input-icon {
  position: absolute;
  fill: $font-color;
}

.white-icon {
  fill: white;
}

.circle {
  border-radius: 2rem;

  &.center {
    margin: 0.15rem 0.25rem 0.2rem 0.2rem;
  }
}

.form-group {
  padding: 0.4rem 0.8rem;
  margin: 0 !important;
  font-weight: 100;
  border-radius: $radius !important;

  label {
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }

  input {
    border: $border-strength solid $input-border-color;
    -webkit-box-shadow: inset 0 0 0 0.03rem $input-border-color;
    border-radius: $input-border-radius;
  }

  textarea {
    border: $border-strength solid $input-border-color;
    -webkit-box-shadow: inset 0 0 0 0.03rem $input-border-color;
    border-radius: $input-border-radius;
  }

  select {
    border: $border-strength solid $input-border-color;
    -webkit-box-shadow: inset 0 0 0 0.03rem $input-border-color;
    border-radius: $input-border-radius;
  }
}

/**
BUTTONS STYLING
 */
.btn {
  border-radius: $input-border-radius !important;
  border-style: solid !important;

  &.btn-outline {
    background: white;
  }

  &.btn-outline-secondary {
    border-color: $grey-5;
    color: $font-color;
    background: white;

    &:hover {
      color: white !important;
      background-color: $grey-5 !important;
      border-color: $grey-5 !important;
    }
  }

  &.btn-outline-success {
    border-color: $green-2;
    color: $green-2;
    background: white;

    &:hover {
      color: white;
      background-color: $green-2;
      border-color: $green-2;
    }
  }

  &.btn-outline-primary {
    border-color: $blue-1;
    color: $blue-1;
    background: white;

    &:hover {
      color: white;
      background-color: $primary-bg;
      border-color: $primary-bg;
    }
  }

  &.btn-outline-danger {
    border-color: $red-2;
    color: $red-2;
    background: white;
  }

  &.btn-outline-warning {
    border-color: $warning-border;
    color: $warning-text;
    background: white;
  }

  &.btn-outline-hint {
    border-color: $input-border-color;
    color: $input-hint-icon-color;
    fill: $input-hint-icon-color;
    background: $input-hint-background-color;
    border-width: 1px !important;
  }

  &.btn-hashtag {
    background: $input-hashtag-background-color;
    color: white;
    fill: white;
  }

  &.btn-outline-hashtag {
    border-color: $input-hashtag-background-color;
    color: $input-hashtag-background-color;
    background: #fff;

    &.active {
      background: $input-hashtag-background-color;
      color: white;
      fill: white;
    }

    &.icon-padding {
      padding-right: 0.1rem;
    }
  }
}

.hint {
  fill: $input-hint-icon-color;
}

.btn-sm {
  border-radius: $small-radius !important;
  border-width: 1px !important;
  font-weight: bold !important;
}

/**
 MODALS STYLING
 */
.modal-dialog {

  .modal-header {
    border-bottom: none;

    .close {
      opacity: 1;

      span {
        font-size: 1vw;
        color: white;
      }
    }
  }

  .modal-title {
    width: 100%;
  }

  .modal-content {
    border-radius: $radius !important;
  }

  .modal-footer {
    border-top: none;
    width: 100%;
  }

  &.info {
    .modal-content {
      background: $info-bg;
      border: 1px solid $info-border;
      color: $info-text;
      fill: $info-text;
    }
  }

  &.warning {
    .modal-content {
      background: $warning-bg;
      border: 1px solid $warning-border;
      color: $warning-text;
      fill: $warning-text;
    }
  }

  &.success {
    .modal-content {
      background: $success-bg;
      border: 1px solid $success-border;
      color: $success-text;
      fill: $success-text;
    }
  }

  &.danger {
    .modal-content {
      background: $danger-bg;
      border: 1px solid $danger-border;
      color: $danger-text;
      fill: $danger-text;
    }
  }
}

.dropzone {
  border-color: $secondary-border !important;
  background-color: $grey-4 !important;
  color: $grey-9 !important;
}

/**
  ALERTS Styling
 */
.alert {
  border-radius: $radius !important;
  padding: 0.5rem;

  &.alert-info {
    background: $info-bg;
    border: 1px solid $info-border;
    color: $info-text;
    fill: $info-text;
  }

  &.alert-warning {
    background: $warning-bg;
    border: 1px solid $warning-border;
    color: $warning-text;
    fill: $warning-text;
  }

  &.alert-success {
    background: $success-bg;
    border: 1px solid $success-border;
    color: $success-text;
    fill: $success-text;
  }

  &.alert-danger {
    background: $danger-bg;
    border: 1px solid $danger-border;
    color: $danger-text;
    fill: $danger-text;
  }
}

/* ALERT FLOATING */
.floating-info-container {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: $z-index-high;
  width: 25%
}

.floating-info-container .alert {
  margin-bottom: 0;
  padding: 0.8rem 1rem;
}

/**
 BG classes styling
 */
.starter {
  .bg-front {
    color: $card-color;
    fill: $card-icon-color;
  }

  .bg-info {
    color: $secondary-text !important;
    fill: $secondary-text !important;
    border: 1px solid $blue-8 !important;
    background: $blue-7 !important;
  }

  .bg-danger {
    color: $danger-text !important;
    fill: $danger-text !important;
    border: 1px solid $danger-border !important;
    background: $danger-bg !important;
  }

  .bg-success {
    color: $success-text !important;
    fill: $success-text !important;
    border: 1px solid $success-border !important;
    background: $success-bg !important;
  }

  .bg-secondary {
    color: $secondary-text !important;
    fill: $secondary-text !important;
    border: 1px solid $secondary-border !important;
    background: $secondary-bg !important;
  }
}


/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  height: 100%;
  background-color: transparent;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */

  .card {
    position: absolute;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);

    .btn {
      width: 8rem;
      font-weight: 600;
      z-index: 20;
    }
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: -webkit-transform 0.8s;
  -moz-transition: -webkit-transform 0.8s;
  -ms-transition: -webkit-transform 0.8s;
  -o-transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-color: transparent;
}

.flipped {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flipped .flip-card-inner {
  -ms-transform: rotateY(180deg) translateZ(1px);
  -webkit-transform: rotateY(180deg) translateZ(1px);
  -moz-transform: rotateY(180deg) translateZ(1px);
  -o-transform: rotateY(180deg) translateZ(1px);
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: $radius;
  border: none !important;

  &.george {
    &:after {
      border-top-right-radius: $radius;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-top: 9rem solid $yellow-1;
      border-left: 9rem solid white;
      width: 0;
    }

    .george-icon {
      z-index: 20;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1.5rem;
      fill: $grey-8;
    }
  }

  &.frequency {
    .frequency-icon {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      padding: 1.5rem;
      fill: #ffdd21;
    }
  }
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: white !important;

  &.selected {
    pointer-events: none;
  }

  &.btn-hide {
    .btn {
      visibility: hidden;
    }
  }
}

/* Style the back side */
.flip-card-back {
  -ms-transform: rotateY(180deg) translateZ(1px);
  -webkit-transform: rotateY(180deg) translateZ(1px);
  -moz-transform: rotateY(180deg) translateZ(1px);
  -o-transform: rotateY(180deg) translateZ(1px);
  transform: rotateY(180deg) translateZ(1px);

  //&.bg-white {
  //  -webkit-transition: background 1000ms linear;
  //  -ms-transition: background 1000ms linear;
  //  transition: background 1000ms linear;
  //}
  //
  //&.bg-danger {
  //  -webkit-transition: background 1000ms linear;
  //  -ms-transition: background 1000ms linear;
  //  transition: background 1000ms linear;
  //}
  //
  //&.bg-success {
  //  -webkit-transition: background 1000ms linear;
  //  -ms-transition: background 1000ms linear;
  //  transition: background 1000ms linear;
  //}
}

.card {
  border-radius: $radius !important;

  &.entity {
    .smart-icon {
      fill: $grey-5;
    }

    label {
      color: $font-color;
    }

    &.compressed {
      border: 1px solid $secondary-border;
    }

    &:not(.compressed) {
      background: $grey-11;
      border: none;
    }
  }
}

.category {
  .inactive {
    opacity: 0.5;
    background: $grey-3;
  }

  .smart-icon {
    fill: $grey-5;
  }

  h5 span {
    font-size: 1.3rem;
    vertical-align: bottom;
    font-weight: bold;
    letter-spacing: -0.05rem;
  }
}

.card-case-type {
  height: 100%;
  position: relative;
  background-color: transparent;
  -webkit-transition: min-height $card-transition-duration;
  -moz-transition: min-height $card-transition-duration;
  -ms-transition: min-height $card-transition-duration;
  -o-transition: min-height $card-transition-duration;
  transition: min-height $card-transition-duration;
  transition-timing-function: ease-in-out;

  .card-footer {
    padding: 0;
    border-top: none;
    background-color: transparent;
  }
}

hr {
  border-top: 0.2rem solid $grey-10;
}

.type-area {
  background-color: $blue-3;
}

.jumbotron {
  background-color: transparent !important;
  border-radius: 0 !important;

  .info {
    background-color: #4588b3 !important;
  }
}

.font-size-15 {
  font-size: 1.5rem;
}

.font-size-13 {
  font-size: 1.3rem;
}

.font-size-12 {
  font-size: 1.2rem;
}

.font-size-115 {
  font-size: 1.15rem;
}

.font-size-11 {
  font-size: 1.1rem;
}

.font-size-10 {
  font-size: 1.0rem;
}

.font-size-05 {
  font-size: 0.5rem;
}

/**
minor tweaks
 */
.selectable {
  cursor: pointer;
}

/**
loading
 */
.loading {
  color: $grey-3;

  .span {
    color: $grey-3;
  }
}

/* LOADING LAYER */
.loading-layer {
  position: relative;

  &.full {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .background {
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .symbols {
    z-index: 2;
    position: absolute;
    top: 15rem;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    text-align: center;
  }
}

.selectable {
  cursor: pointer;
}

.modal-dialog.borderless .modal-body {
  padding: 0;
}

.popover {
  -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid $grey-4 !important;
  max-width: 500px !important;

  .popover-body {
    font-size: 1.2rem !important;
    background-color: $card-color;
    color: white;
    border-radius: $input-border-radius;
  }

  .arrow::after {
    border-bottom-color: $card-color !important;
  }
}

/*
Bootstrap override
 */
@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

$boxWidth: 33px;
$animationDuration: 15s;
$animationStepDuration: $animationDuration / 7;

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }

  2% {
    transform: translate($boxWidth, 0);
  }

  12.5% {
    transform: translate($boxWidth, 0);
  }

  15.5% {
    transform: translate(2*$boxWidth, 0);
  }

  25% {
    transform: translate(2*$boxWidth, 0);
  }

  27% {
    transform: translate(2*$boxWidth, $boxWidth);
  }

  37.5% {
    transform: translate(2*$boxWidth, $boxWidth);
  }

  39.5% {
    transform: translate($boxWidth, $boxWidth);
  }

  50% {
    transform: translate($boxWidth, $boxWidth);
  }

  52% {
    transform: translate($boxWidth, 2*$boxWidth);
  }

  62.5% {
    transform: translate($boxWidth, 2*$boxWidth);
  }

  64.5% {
    transform: translate(0, 2*$boxWidth);
  }

  75% {
    transform: translate(0, 2*$boxWidth);
  }

  77% {
    transform: translate(0, $boxWidth);
  }

  87.5% {
    transform: translate(0, $boxWidth);
  }

  89.5% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes gradient1 {
  from {
    stop-color: #4383b8;
  }

  50% {
    stop-color: #30c925;
  }

  to {
    stop-color: #4383b8;
  }
}

@keyframes gradient2 {
  from {
    stop-color: #30c925;
  }

  50% {
    stop-color: #ffdd21;
  }

  to {
    stop-color: #30c925;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

svg#loading {
  display: block;
  margin: auto;
  width: 5rem;
  height: auto;
  //transform : rotate(180deg);
  //animation: rotate $animationDuration / 2 linear 0s infinite;

  #gradient>stop[offset="0%"] {
    animation: gradient1 $animationDuration ease 0s infinite;
  }

  #gradient>stop[offset="100%"] {
    animation: gradient2 $animationDuration ease 0s infinite;
  }

  .rect {
    animation: slide $animationDuration ease infinite;
  }

  #rect1 {
    animation-delay: -0 * $animationStepDuration;
  }

  #rect2 {
    animation-delay: -1 * $animationStepDuration;
  }

  #rect3 {
    animation-delay: -2 * $animationStepDuration;
  }

  #rect4 {
    animation-delay: -3 * $animationStepDuration;
  }

  #rect5 {
    animation-delay: -4 * $animationStepDuration;
  }

  #rect6 {
    animation-delay: -5 * $animationStepDuration;
  }

  #rect7 {
    animation-delay: -6 * $animationStepDuration;
  }
}

.modal-dialog.tablet-modal.modal-xl {
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: auto !important;
  //Higher than loading-layer overlay
  z-index: 20000;
  position: absolute;
}

.tablet-modal .modal-content .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tablet-modal .modal-content {
  height: 100%;
}

.tablet-modal .row {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tablet-modal div.continue-footer {
  display: flex;
  justify-content: center;
}